
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.sidebar .nav p,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general{
    @include transitions($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.bootstrap-switch-label:before,
.caret{
    @include transitions($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i{
    @include rotate-180();
}

.caret{
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: -5px;
  position: absolute;
  top: 30px;
  margin-left: 5px;
}

.pull-left{
  float: left;
}
.pull-right{
  float: right;
}


// card user profile page

.card {
  form {
    label + .form-control {
      margin-bottom: 5px;
    }
  }
}

.card {
  .map-title {
    color: $white;
  }

  &.card-chart {
    .gmnoprint,
    .gm-style-cc {
      display: none !important;
    }
  }
}

// documentation

.bd-docs {

  h1,h2,h3,h4,h5,h6,p,ul li,ol li{
    color:#2c2c2c;
  }


  .bd-content>table>thead>tr>th {
    color: $black;

  }

  .blockquote, .blockquote p, .card p{
    color: rgba($white,0.8);
  }
  .bd-example {
    background: linear-gradient(#1e1e2f,#1e1e24);

  }

  .navbar {
    border-top: none;

    .navbar-nav .nav-link {
      color: rgba(255,255,255,.8) !important;
    }
  }

  .bd-example {

    .btn{
      margin: 4px 0;
    }
    .btn .badge {
      display: inline-block;
    }

    .tim-icons{
      color: $white;
    }

    .popover .popover-header {
    color: hsla(0,0%,71%,.6);
  }


    .popover-body {
      p {
        color: $gray-900;
      }
    }

    &.tooltip-demo p{
      color: rgba($white,.8);
    }
  }

  .card.card-body,
  .card .card-body {
    color: hsla(0,0%,100%,.8);
  }

  label,
  .form-check {
    color: hsla(0,0%,100%,.8);
  }

  .form-check + .btn {
    margin-top: 20px;
  }

  .bd-example,
  table {
    thead th {
      color: hsla(0,0%,100%,.8);
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: hsla(0,0%,100%,.8);
    }

    .datepicker{
      thead th, table thead th,.tim-icons{
        color: $primary;
      }

    }

    .picker-switch .tim-icons{
      color: $primary;
    }
  }

  .footer {
    .container-fluid > nav {
      display: inline-block;
    }
  }
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0,30%);
  transform: translate(0,30%);
}

code {
  color: $pink;
}

.login-logo {
  max-width: 400px;
}

@media screen and (max-width: 991px){
    .profile-photo .profile-photo-small{
        margin-left: -2px;
    }

    .button-dropdown{
        display: none;
    }

    #searchModal .modal-dialog{
      margin: 20px;
    }

    #minimizeSidebar{
        display: none;
    }

}

blockquote {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid lighten($black, 40%);
  font-family: 'Roboto', serif;
  font-weight: 100;
  &:before, &:after {
      content: '\201C';
      font-family: 'Sanchez';
      color: lighten($black, 40%);
   }
   &:after {
      content: '\201D';
   }
   padding-top: .5em;
   padding-bottom: .5em;
}

blockquote.right {
  border-right: 0.2em solid lighten($black, 40%);
  border-left: unset;
}

blockquote.success {
  border-left: 0.2em solid lighten($success, 40%);
  background-color: lighten($success, 65%);
}

blockquote.warning {
  border-left: 0.2em solid lighten($warning, 40%);
}

// .flippy-container {
//   height:100%;
// }

.flippy-front, .flippy-back {
  padding:0;
}

.logo-image {
  width: 47px;

  @include media-breakpoint-up("xl") {
    width: 57px;
  }
}

.burger-container {
  display: inline-block;
  cursor: pointer;
}

.burger-bar1, .burger-bar2, .burger-bar3 {
  width: 25px;
  height: 3px;
  background-color: $header-gray;
  margin: 5px 0;
  transition: 0.4s;
}

.burger-change .burger-bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.burger-change .burger-bar2 {opacity: 0;}

.burger-change .burger-bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}

.burger-thumbnail {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #00649426;
  color: #2E5BFF;
  cursor: pointer;
}

.mail-icon {
  font-size: 30px;
  color: $header-gray;
  top: -3px;
  position: relative;
  display: inline;
}

#support-ticketing-icon {
  font-size: 26px;
}

#tabsDashboard {
  display: none;
}

.nav-tabs .nav-link.active {
  background-color:unset;
  border-color: #e3e3e3 #e3e3e3 #f5f6fa;
}

.card-flip-button span {
  color: white;
}

.card-notification-bell {
  top: -13px
}

.unread-indicator {
  font-size: 6px;
  // top: -8px;
  position: absolute;
}

.alert-app {
  position: fixed;
  z-index: 100;
  top:0;
}

.alert-app .alert {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.app-nav-title, .app-back-title {
  color: $gray-900;
  font-size: $font-size-lg;
}

.btn.btn-link.dropdown-toggle.btn-image-dd {
  box-shadow: unset !important;
}

.btn.btn-link.dropdown-toggle.btn-image-dd::after {
  // margin-left:3px !important;
  position: absolute;
}

.new-ticket-button {
  cursor: pointer;
}

.carousel .slide {
  background: white;
}

div.carousel-control-dots-abs {
  height: 100%;

  .carousel {
    height: inherit;
    display: flex;
    flex-direction: column;

    .slider-wrapper {
      flex: 1;

      .slider { height: 100%; }
    }

    .control-dots {
      position: relative;
      margin-bottom:0;
      margin-top:10px;
      padding-left:0;
    }
  }
}

#table-resource-matrix > tbody > tr > td {
  border:unset;
  padding-top:3px;
  padding-bottom:3px;
  text-align:center;
}

@media screen and (max-width: 768px){

    .landing-page .section-story-overview .image-container:nth-child(2){
        margin-left: 0;
        margin-bottom: 30px;
    }

    #tabsDashboard {
      display: flex;
    }

    .app-back-title {
      font-size: $font-size-sm;
    }
}

@media screen and (max-width: 576px){
    .page-header{
        .container h6.category-absolute{
            width: 90%;
        }
    }

    .form-horizontal .col-form-label, .form-horizontal .label-on-right{
      text-align: inherit;
      padding-top: 0;
      code{
        padding: 0 10px;
      }
    }
}

.compliance-gauge-chart .c3-chart-arc .c3-gauge-value {
  font-size: 13px !important;
  fill: rgba(34, 42, 66, 0.7);
}

.clickable {
  color: #0098f0;
}

.clickable:hover {
  color: #0C618D;
}

.tooltip-inner {
  color: white;
  background-color: #000000;
}

.border-bottom-2 {
  border-bottom: solid 2px #ddd;
}

@media (max-width: 576px) {
  .hide-sm {
    display: none !important; 
  }
}