@media (min-width: 576px) {
    .flex-row-container{ font-size: 0.875rem; }
}
@media (max-width: 575px) {
    /* th{ font-size: 0.825rem; }
    td{ font-size: 0.7rem; } */
}
@media (min-width: 768px) {
    .flex-row-container{ font-size: 0.875rem; }
    .bp-account-filter{ text-align: center; }
    .bp-importance-filter{ text-align: right; }
}
.col-5-width{
 max-width: 20%!important;
}
.bp-Informational {
  border-left: solid 4px rgb(12, 97, 141) !important;
}
.bp-Medium {
  
  border-left: solid 4px rgb(236, 114, 17) !important;
}
.bp-Low {
  border-left: solid 4px #ffd600 !important;
}
.bp-High {
  border-left: solid 4px #D1482F !important;
}
.bp-Addressed {
  border-left: solid 4px #2dce89 !important;
}

.bp-text-Informational {
  color: rgb(12, 97, 141) !important;
}
.bp-text-Medium {
  color: rgb(236, 114, 17) !important;
}
.bp-text-Low {
  color: #ffd600 !important;
}
.bp-text-High {
  color: #D1482F !important;
}
.bp-text-Addressed {
  color: #2dce89 !important;
}

.flex-row-container {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.flex-row-container :first-child {
    /* width: 20px; */
  }
.flex-row-container > .flex-row-item {
    flex: 0 0 25%;
    height: 30px;
    flex-shrink: 0;
}
.flip{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.circle {
    height: 13px;
    width:13px;
    margin-left: 2px;
    border-radius: 50%;
    display: inline-block;
  }
.circle.ok{
    background-color: green!important;
 
  }
  .account-selected{
    background-color: aquamarine;

  }
   .circle.warning{
   background-color: orange!important;
   color: orange!important;
 }
 .circle.danger{
  background-color: red!important;
   color: red!important;
 }
.flex-row-item {

} 
.page-link{
  color: #525f80!important;
}
.page-item.active .page-link { color: white!important;}
td {
  max-width: 100px;
}
.card-home td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.savings{
    padding-top: 15px;
}
.cursor{
  cursor: pointer;
}

.construction { display: block; text-align: left; width: 650px; margin: 0 auto;
  font: 20px Helvetica, sans-serif; color: #333; }
 .contrsuction-a {
  color: #dc8100; text-decoration: none;
 } 
.pulse {

    margin: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #d1812fbf;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 1.8s infinite;
  }
  .pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }