$red1:              #D63649 !default;
$red1-15:           rgba(214, 54, 73, 0.15) !default;
$red2:              #D24727 !default;
$green1:            #33AC2E !default;
$green1-15:         rgba(51, 172, 46, 0.15) !default;
$green1-50:       rgba(51, 172, 46, 0.50) !default;
$green2:            #1BC5BD !default;
$blue1:             #2E5BFF !default;
$blue1-15:          rgba(46, 91, 255, 0.15) !default;
$gray1:            #F4F6FC !default;
$gray2:            #BFC5D2 !default;
$gray2-30:         #bfc5d23f !default;
$gray3:            #B0BAC9 !default;
$gray4:            #8798AD !default;
$gray4-15:          rgba(135, 152, 173, 0.15) !default;
$gray5:             #E0E7FF !default;
$gray6:             #2E384D !default; 
$yellow1:            #F0E65B !default;
$yellow1-15:        rgba(240, 230, 91, 0.15) !default;
$purple1:           #745fb5 !default;
$purple1-15:        rgba(117, 95, 181, 0.15) !default;

.red {
    color: #D63649;
}
.green {
    color: #33AC2E;
}
.green-50op {
    color: rgba(51, 172, 46, 0.50);
}
.blue {
    color: #2E5BFF;
}
.gray {
    color: #BFC5D2;
}
.yellow {
    color: #F0E65B;
}

.content-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    @media (min-width: 576px) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 50px
    }
    @media (min-width: 992px) {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 50px
    }
}

.hs {
    @media (max-width: 768px) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, calc(50% - 40px));
        grid-template-rows: minmax(250px, 1fr);
        overflow-x: scroll;
        scroll-snap-type: x proximity;
    }
    /*@media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }*/
  }

.my-hs {
    @media (max-width: 768px) {
        display: flex;
        overflow-x: scroll;
    }
    @media (min-width: 768px) {
        display: flex;
    }
}

.gauge-col-wrapper {
    
    min-width: 260px;
    /*@media (min-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 700px) {
        flex: 0 0 40%;
        max-width: 40%;
    }*/
    @media (min-width: 900px) {
        flex: 0 0 30%;
        max-width: 30%;
        
    }
    @media (min-width: 1200px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.gauge-inner-dots > g > path {
    fill: $gray5;
    stroke: white;
}
.gauge-outer-ticks > g > path {
    fill: $gray5;  
}
.gauge-outer-ticks > g > path[color=blue] {
    fill: $blue1;
}
.gauge-outer-ticks > g > path[color=yellow] {
    fill: $yellow1
}
.gauge-outer-ticks > g > path[color=red] {
    fill: $red1
}
// Gauage value label
.gauge-outer-ticks > text:first-of-type {
    transform: translateY(10px);
    fill: #2E384D !important;
    font-family: "Encode Sans", sans-serif;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 47px;
}
// Gauge unit label
.gauge-outer-ticks > text:last-of-type {
    transform: translateY(40px);
    fill: #8798AD !important;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 15px;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-scroll-tablet {
    @media (max-width: 992px) {
        overflow-x: scroll;
    }
}

.overflow-x-scroll-lg {
    @media (max-width: 1200px) {
        overflow-x: scroll;
    }
}

.tria-card {
    background: white;
    position: relative;
    //width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin: 10px;
    box-shadow: 0 10px 20px 0 rgba(46,91,255,0.07);
}
.tria-card-header {
    padding: 32px 15px 0 24px;
    background-color: white;
}
.tria-card-title {
    color: #8798AD !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    //font-family: ".SF NS Display";
    line-height: 16px;
    letter-spacing: 1.21px;
    margin-bottom: .75rem;
    text-transform: uppercase;
}
.tria-card-body {
    padding: 15px;
    flex: 1 1 auto;
}
.tria-card-footer {
    padding: 15px;
}

div[class^="col-"] > .tria-card {
    margin: 10px 5px 10px 5px;
}



h1.tria-header, .tria-header a  {
    color: #2E384D;
    font-family: Encode Sans, sans-serif !important;
    font-size: 28px !important;
    font-weight: 500 !important;
    letter-spacing: 0;
    line-height: 32px;
}
.tria-header a {
    color: #2E5BFF !important;
}
.tria-header-container {
    padding: 0 8px 15px;
    @media (min-width: 576px) {
        padding: 0 8px 0px;
    }
    @media (min-width: 992px) {
        padding: 0 8px 20px;
    }
}

.bar {
    height: 4px; 
    width: 19px;
    border-radius: 99px;
    margin-top: 3px; 
    margin-bottom: 3px; 
    margin-left: 2px; 
    margin-right: 2px;
}

.collapse-button {

    width: 100%;
    height: 40px;
    border-radius: 4px;
    color: white;

    @media (min-width: 576px) {
        width: 225px;
    }
}
.collapse-button,
.collapse-button:hover,
.collapse-button:active,
.collapse-button:focus,
.collapse-button:focus:active {
    background: #2E5BFF !important;
}

.gray-button {
    height: 40px;
    border-radius: 4px;
    color: #8798AD !important;

    font-family: 'Encode Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
}
.gray-button,
.gray-button:hover, 
.gray-button:active, 
.gray-button:focus, 
.gray-button:focus:active {
    background-color: #F4F6FC !important;
    background: #F4F6FC !important;
}


// Status Icon Token
.status-icon-token {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;

    i { padding-top: 13px; }
}
.status-icon-token[color="green"] {
    background-color: $green1-15;
    i { color: $green1; }
}
.status-icon-token[color="yellow"] {
    background-color: $yellow1-15;
    i { color: $yellow1; }
}
.status-icon-token[color="red"] {
    background-color: $red1-15;
    i { color: $red1; }
}
.status-icon-token[color="blue"] {
    background-color: $blue1-15;
    i { color: $blue1; }
}
.status-icon-token[color="gray"] {
    background-color: $gray4-15;
    i { color: $gray4; }
}
.status-icon-token[color="purple"] {
    background-color: $purple1-15;
    i { color: $purple1; }
}


// Status Icon
.status-icon[color="red"] {
    color: $red2;
}
.status-icon[color="green"] {
    color: $green1-50;
}
.status-icon[color="yellow"] {
    color: $yellow1;
}
.status-icon[color="blue"] {
    color: $blue1;
}
.status-icon[color="gray"] {
    color: $gray2;
}
.status-icon[color="purple"] {
    color: $purple1;
}

// Health Bars
.health-bar[color="red"] {
    background-color: $red2;
}
.health-bar[color="green"] {
    background-color: $green2;
}
.health-bar[color="yellow"] {
    background-color: $yellow1;
}
.health-bar[color="gray"] {
    background-color: $gray2;
}
.health-bar.empty-bar {
    border: 1px solid $gray2;
    background-color: $gray2-30;
}

.health-bar-label {
    color: #8798AD !important;
    font-family: 'Roboto', sans-serif;
    font-size: 6px;
    letter-spacing: 0;
}

.tria-btn {
    font-family: 'Encode Sans', sans-serif;
    font-size: 15px;
    letter-spacing: 1.21px;
    line-height: 16px;
    font-weight: 300;
}
.tria-btn-blue,
.tria-btn-blue:hover,
.tria-btn-blue:active,
.tria-btn-blue:focus,
.tria-btn-blue:focus:active {
    background: #2E5BFF !important;
}
.tria-btn[color="gray"] {
    
}

.expand-table-btn {
    height: 20px;
    width: 135px;
    background-color: #F4F6FC;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    text-align: center;

    color: #B0BAC9;
    font-family: Helvetica;
    font-size: 12px;
    letter-spacing: 1.13px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    min-width: 36px;
    height: 20px;
}
 /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* ToggleSwitch / slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #24AECE;
}
input:focus + .slider {
    box-shadow: 0 0 1px #24AECE;
}
input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

p.label-font {
    color: #8798AD !important;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 22px;
}

.mw-rw-datetime-picker .rw-container {
    height: 100%;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    
    .rw-select.rw-select-bordered {
        border-left: 1px solid #E0E7FF;
    }

    .rw-select .rw-btn.rw-btn-select {
        width: 25px;
    } 
}

.mw-rw-datetime-picker.rw-state-focus > .rw-input {
    background-color: rgba(224,231,255,0.2);
    border: 0;
    box-shadow: none;
}

.mw-rw-datetime-picker.rw-state-focus > .rw-container {
    box-shadow: none;
}

.rw-dropdown-font > .rw-input {
    color: $gray4;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}

.tria-input {
    box-sizing: border-box;
    border: 1px solid #E0E7FF !important;
    border-radius: 5px;
    background-color: rgba(224,231,255,0.2);
    font-size: $font-size-sm;
}
.tria-input:focus {
    background-color: rgba(224,231,255,0.2);
    box-shadow: none;
}
.tria-input > .rw-input {
    background-color: rgba(224,231,255,0.2);
    color: #222a42;  
}
.tria-input > .rw-input:active {
    background-color: rgba(224,231,255,0.2);
    color: #222a42;  
}
.tria-input .rw-widget-input {
    box-shadow: none;
}
.tria-input-font {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}

.roboto-15 {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}

p.sub-header {
  color: #2E384D;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 500;
}

.helvetica-font {
    color: #8798AD !important;
    font-family: Helvetica, sans-serif;;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
}

.content-panel-container {
    position: absolute;
    width: 80%;
    height: 100%;
    z-index: 999;
    margin-top: -8px;
    left: 0;
    background-color: #ffffff;
    border-radius: 0;
    transition: transform 0.3s 0.3s;
    transform: translate3d(-100%, 0, 0);
}

.content-panel {
    visibility: hidden;
    transition: visibility 0s 0.6s;
}

.content-panel.content-panel--is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
}

/*.content-panel--is-visible .my-blade-panel-header {
    transition: transform 0.3s 0.3s;
    transform: translateY(0px);
}*/

.content-panel--is-visible .content-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
}

.content-panel-backdrop {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);
    width:100%;
    height: 100%;
    z-index: 998;
}


.tria-table {
    border-collapse:separate; 
    border-spacing:0 12px; 
}
.tria-table > thead > tr > th {
    color: #BFC5D2 !important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    //font-family: ".SF NS Display";
    font-size: 12px;
    letter-spacing: 1.12px;
    line-height: 15px;
}
.white-content .tria-table > thead > tr > th {
     // Border spacing gives padding already, remove excess
     padding-top: 0px;
     padding-bottom: 0px;
     text-transform: uppercase;
}
.white-content .tria-table > tbody > tr > td {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    border-top: 1px solid rgba(46,91,255,0.08) !important;
    border-bottom: 1px solid rgba(46,91,255,0.08) !important;
    border-radius: 1px;
}

@media screen and (max-width: 992px) {
    .tablet-hidden { display: none; }  
  }
  @media screen and (max-width: 576px) {
    .mobile-hidden { display: none; }  
  }
  @media screen and (min-width: 576px) {
    .mobile-shown { display: none; }  
  }

.text-action {
    color: #2E5BFF !important;
    font-family: 'Encode Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
}

.card-field-value > div:first-child > p {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0;
    color: $gray6;
}
.card-field-value > div:last-child > p {
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 13px;
    color: $gray4;

    margin-top: .3rem;
    word-break: break-word;
}
.card-sub-header {
    color: #8798AD !important;
    font-family: 'Encode Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 1.21px;
    line-height: 16px;
    text-transform: uppercase;

    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.card-content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    @media (min-width: 768px) {
        padding-left: 1.5rem;
        padding-right: 0rem;
    }
    @media (min-width: 992px) {
        padding-left: 1.5rem;
        padding-right: 3rem;
    }
    
    margin-bottom: 1.5rem;
}

.mw-card-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @media (min-width: 768px) {
        padding-left: 1.1rem;
        padding-right: 1.5rem;
    }
}

.break-word {
    word-break: break-word;
}

.tria-alert {
    @media (min-width: 1200px) {
        padding-left: 90px;
    }
}
.card.card-nextgen {
  .card-header {
    background: white;
    border-bottom: 0;
  }
  .card-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #8798AD;
    margin-bottom: 0;
  }
  h5, label:not(.btn) {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #8798AD;
  }

  .col-border-right {
    border-right: solid 2px #d8d8d8;
  }

  .btn-outline-primary {
    background: white !important;
    border: solid 1px #ddd;
    font-weight: normal;
    color: #000;
    &.active {
      border-color: #2E5BFF;
      color: inherit;
    }
    .fas {
      color: #2E5BFF;
    }
    .small {
      color: #8899AE;
    }
  }
}

.upsell-card {
  background-size: cover;
  font-family: 'Encode Sans', sans-serif;
  padding: 15px;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .body {
    display: flex;
    flex-direction: column;

    .hero-wrapper {
      flex: 1;
      align-self: stretch;
      margin-bottom: 10px;

      .hero { width: 100%; }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: flex-end;
      padding: 0;

      .description {
        align-self: stretch;
        text-align: center;
      }

      .action-btn {
        display: block;
        align-self: stretch;
        margin-top: 15px;
      }
    }
  }

  &.desktop {
    padding: 20px 25px;

    .body {
      flex-direction: row;

      .hero-wrapper { margin-bottom: 20px; }

      .content {
        flex: 1;
        margin-left: 10px;
      }

      &.row-reverse {
        flex-direction: row-reverse;

        .content { margin-left: 0; margin-right: 10px; }
      }
    }
  }
}

.ops-form {
  input { font-size: 1rem; }
}
